import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import {useForm} from "react-final-form";
import {useDataProvider} from "react-admin";
import {
    ApiCarColor,
    ApiCarGrade,
    ApiProduct,
    ApiProductVariantsData,
    ApiVariantClass,
    ApiVariantItemProductPrice,
} from "../../openapi";
import {MAX_FETCH_DATA_SIZE} from "../../config/const";

interface PriceProps {
    calcPrice: number;
    setCalcPrice: Dispatch<SetStateAction<number>>;
    productInitialValues: number[] | undefined
    setProductInitialValues: Dispatch<SetStateAction<number[] | undefined>>;
    completeCarId?: number;
    defaultVariantItemIds: number[] | undefined;
    selectAffectPriceVariantItemIds: number[] | undefined;
}

const Price: React.FC<PriceProps> = (props) => {

    const form = useForm();
    const dataProvider = useDataProvider();

    const [salePriceState, setSalePriceState] = useState(0);
    const [costState, setCostState] = useState(0);
    const [discountPriceState, setDiscountPriceState] = useState(0);

    useEffect(() => {
        const awaitFn = async () => {
            const values = form.getState().values;
            const defaultCarGradeId = values['defaultCarGradeId'];
            const defaultCarColorId = values['defaultCarColorId'];
            const defaultProductIds = values['defaultProducts'] as number[] | undefined;
            const discountPrice = values['discountPrice'] as number | undefined;
            let salePrice = 0;
            if (discountPrice) {
                setDiscountPriceState(discountPrice);
                salePrice -= discountPrice;
            }
            let cost = 0;
            if (defaultCarGradeId) {
                const carGradeRawData = await dataProvider.getOne('carGrades', {
                    id: defaultCarGradeId
                });
                const carGradeData = carGradeRawData.data as ApiCarGrade;
                console.log('carGradeData.price', carGradeData.price)
                salePrice += carGradeData.price;
                cost += carGradeData.cost;
            }
            if (defaultCarColorId) {
                const carColorRawData = await dataProvider.getOne('carColors', {
                    id: defaultCarColorId
                });
                const carColorData = carColorRawData.data as ApiCarColor;
                console.log('carColorData.price', carColorData.price)
                salePrice += carColorData.price;
                cost += carColorData.cost;
            }

            const getVariantItemProductPriceCombinations = async (id: number) => {
                const result = await dataProvider.getManyNoParams('variantItemProductPriceCombinations/' + Number(id))
                return result.data as Array<ApiVariantItemProductPrice>;
            }

            const getByVariantClassesId = async (variantClassesId: number) => {
                const result = await dataProvider.getOne('variantClasses', {id: variantClassesId});
                return result.data as ApiVariantClass;
            }

            async function extracted(ids: number[]) {
                const productsRawData = await dataProvider.getList('products', {
                    pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                    sort: {field: 'createdAt', order: 'ASC'},
                    filter: {
                        ids: ids
                    }
                });
                console.log('productsData2', productsRawData)
                const productsData = productsRawData.data as ApiProduct[]
                await Promise.all(productsData.map(async (product) => {
                    salePrice += product.price;
                    cost += product.cost;
                    const variantItemProductPriceCombinationsList = await getVariantItemProductPriceCombinations(product.id as number);
                    variantItemProductPriceCombinationsList.map((variantItemProductPriceCombination) => {
                        if (props.selectAffectPriceVariantItemIds?.length === variantItemProductPriceCombination.variantItem?.length) {
                            if (props.selectAffectPriceVariantItemIds?.every((selectVariantItemId) => {
                                return variantItemProductPriceCombination?.variantItem?.find((variantItem) => variantItem.id === selectVariantItemId)
                            })) {
                                salePrice += variantItemProductPriceCombination.price ? variantItemProductPriceCombination.price * 4 : 0;
                                cost += variantItemProductPriceCombination.cost ? variantItemProductPriceCombination.cost * 4 : 0;
                            }
                        }
                    })
                }))
            }

            if (defaultProductIds && defaultProductIds.length) {
                await extracted(defaultProductIds);
            } else if (props.productInitialValues && props.productInitialValues.length) {
                const uniqueArray = Array.from(new Set(props.productInitialValues));
                await extracted(uniqueArray);
            }

            if(props.completeCarId){
                const getCompleteCarDefaultVariantItems = async () => {
                    const result = await dataProvider.getList('completeCarDefaultVariantItems/' + props.completeCarId,
                        {
                            pagination: {page: 1, perPage: MAX_FETCH_DATA_SIZE},
                            sort: {field: 'id', order: 'asc'},
                            filter: {completeCarId: props.completeCarId},
                        });
                    return result.data as ApiProductVariantsData[]
                }
                console.debug('adad1-start', salePrice)
                const completeCarDefaultVariantItems = await getCompleteCarDefaultVariantItems();
                console.debug('adad2', completeCarDefaultVariantItems)
                await Promise.all(completeCarDefaultVariantItems?.map(async (completeCarDefaultVariantItem) => {
                    console.debug('adad3', completeCarDefaultVariantItem)
                    completeCarDefaultVariantItem.variant?.variantClass?.map((variantClass) => {
                        variantClass.carGrades?.map((carGrade) => {
                            carGrade.variantItem?.forEach((variantItem) => {
                                if (props.defaultVariantItemIds && props.defaultVariantItemIds.includes(variantItem.id as number)) {
                                    console.debug('adad4', variantItem.price)
                                    salePrice += variantItem.price * 4;
                                    cost += variantItem.cost * 4;
                                }
                            })
                        })
                    })
                }))
            }
            console.debug('adad5', salePrice)

            setSalePriceState(salePrice);
            setCostState(cost);
        }
        awaitFn().then();
    }, [props.calcPrice, props.productInitialValues, props.defaultVariantItemIds, props.selectAffectPriceVariantItemIds])
    return (
        <Grid container>
            <Grid item spacing={0} xs={2} sm={2} md={2} lg={2} xl={2}>販売価格</Grid>
            <Grid item spacing={0} xs={2} sm={2} md={2} lg={2} xl={2}>{salePriceState.toLocaleString()}</Grid>
            <Grid item spacing={0} xs={2} sm={2} md={2} lg={2} xl={2}>原価合計</Grid>
            <Grid item spacing={0} xs={2} sm={2} md={2} lg={2} xl={2}>{costState.toLocaleString()}</Grid>
            <Grid item spacing={0} xs={2} sm={2} md={2} lg={2} xl={2}>粗利</Grid>
            <Grid item spacing={0} xs={2} sm={2} md={2} lg={2}
                  xl={2}>{(salePriceState - costState - discountPriceState).toLocaleString()}</Grid>
        </Grid>
    )
}
export default Price;
